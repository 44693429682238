@if $google-font {
  $google-font-url: "https://fonts.font.im/css2?family=" + str-replace($google-font, " ", "+") + ":wght@300;400;500;600;700&display=swap" !default;

  @import url($google-font-url);
}

@if $icon-fonts {
  @each $icon-font in $icon-fonts {
    @import url($icon-font);
  }
}
